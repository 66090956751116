import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { IMegaMenuVisibilitySettings, ISettingsResponse } from '@ncg/data';
import { FeatureDetectionService, HeaderService, ScrollStatusService, SettingsService } from '@ncg/ui';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'ncg-header',
    template: `
        <div class="header__backdrop" [ngClass]="{ 'header__backdrop--is-open': isMegaMenuOverlayVisible }"></div>
        <div class="header__wrapper" [style.height]="headerHeight + 'px'">
            <header
                #header
                class="header"
                [ngClass]="{ 'header--is-sticky': isFixed }"
                [style.transform]="hideNavBar ? 'translate3d(0, ' + -headerHeight + 'px, 0)' : ''"
            >
                <ncg-top-menu class="is-hidden-touch" *ngIf="hasTopMenu" [links]="settings?.topMenuLinks"></ncg-top-menu>
                <div class="header__container">
                    <div class="header__logo">
                        <ng-container *ngIf="settings?.logoLink?.url && settings?.logoLink?.isExternal; else default">
                            <a class="logo" aria-label="BYD logo" [href]="settings?.logoLink?.url" [attr.target]="settings?.logoLink?.target">
                                <ng-container *ngTemplateOutlet="logoSymbolTmpl"></ng-container>
                            </a>
                        </ng-container>

                        <ng-template #default>
                            <a class="logo" aria-label="BYD logo" [routerLink]="['/']">
                                <ng-container *ngTemplateOutlet="logoSymbolTmpl"></ng-container>
                            </a>
                        </ng-template>
                    </div>

                    <ncg-main-menu (isMegaMenuVisibleSettings)="onMegaMenuChange($event)" class="header__main-menu is-hidden-touch"></ncg-main-menu>

                    <ncg-meta-menu class="header__meta-menu is-hidden-touch"></ncg-meta-menu>

                    <div class="header__mobile-menu is-hidden-desktop">
                        <button ncgMobileMenuTrigger type="button" class="button is-clean is-block" aria-label="menu" aria-expanded="false">
                            <div class="mobile-icon">
                                <svg-icon-sprite
                                    src="nav"
                                    [viewBox]="'0 0 30 30'"
                                    [width]="'30px'"
                                    [height]="'30px'"
                                    aria-hidden="true"
                                ></svg-icon-sprite>
                            </div>
                        </button>
                    </div>
                </div>
            </header>
        </div>

        <ng-template #logoSymbolTmpl>
            <svg-icon-sprite src="byd-logo" [viewBox]="'0 0 94 18'" aria-hidden="true"></svg-icon-sprite>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
    private readonly unsubscribe = new Subject<void>();
    private readonly HAS_TOP_MENU_KEY = makeStateKey<boolean>('hasTopMenu');

    @ViewChild('header') private readonly headerElement: ElementRef;

    settings?: ISettingsResponse;
    isMegaMenuOverlayVisible = false;
    isFixed: boolean;
    previousPosition = 0;
    hideNavBar = false;
    headerHeight = 0;
    currentPosition = 0;
    hasTopMenu: boolean;

    constructor(
        private readonly cd: ChangeDetectorRef,
        private readonly transferState: TransferState,
        private readonly headerService: HeaderService,
        private readonly scrollStatusService: ScrollStatusService,
        private readonly featureDetection: FeatureDetectionService,
        private readonly settingsService: SettingsService
    ) {}

    ngOnInit() {
        if (this.transferState.hasKey(this.HAS_TOP_MENU_KEY)) {
            this.hasTopMenu = this.transferState.get(this.HAS_TOP_MENU_KEY, false);
            this.cd.markForCheck();
        }

        this.settingsService
            .get()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((settings) => {
                this.settings = settings;
                this.hasTopMenu = settings.hasTopMenu;

                if (this.featureDetection.isServer()) {
                    this.transferState.set(this.HAS_TOP_MENU_KEY, this.hasTopMenu);
                }

                this.setInitialValues();
            });

        this.scrollStatusService
            .getScrollPosition()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((position: number) => {
                this.currentPosition = position;
                this.handleHeaderPosition();
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    setInitialValues() {
        if (this.featureDetection.isBrowser() && this.headerElement) {
            this.headerHeight = this.headerElement.nativeElement.offsetHeight;
            this.previousPosition = this.headerHeight;
            this.headerService.updateModelPageHeader(this.headerHeight);
        }
        this.cd.markForCheck();
    }

    handleHeaderPosition() {
        if (this.currentPosition > this.headerHeight) {
            if (this.currentPosition > this.previousPosition) {
                this.hideNavBar = true;
                this.previousPosition = this.currentPosition - 1;
            } else {
                this.isFixed = true;
                this.hideNavBar = false;
                this.previousPosition = this.currentPosition;
            }
        } else if (this.currentPosition === 0) {
            this.isFixed = false;
            this.hideNavBar = false;
            this.previousPosition = this.headerHeight;
        }

        this.cd.markForCheck();
    }

    onMegaMenuChange(settings: IMegaMenuVisibilitySettings) {
        this.isMegaMenuOverlayVisible = settings.isVisible;
        this.cd.detectChanges();
    }
}
